import React, { useState, useEffect, useRef } from 'react';
import { Link ,Routes,Route,Router,Switch, useNavigate, useHistory , useLocation } from 'react-router-dom';
import { formatDistanceToNow, parseISO } from 'date-fns';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
import ShareButtons from './components/ShareButtons';
import { toast, Toaster } from "react-hot-toast";
const BASE_URL=process.env.REACT_APP_BASE_URL;
const JobSearch = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const jobsData = sessionStorage.getItem('jobs');
	const [jobs, setJobs] = useState(JSON.parse(jobsData));
	const [filteredJobs, setFilteredJobs] = useState([]);
	const [minSalary, setMinSalary] = useState(0);
	const [maxSalary, setMaxSalary] = useState(200000);
	const [salary, setSalary] = useState(0);
	const [status, setStatus] = useState(null);
	const [locations, setLocations] = useState([]);
	const [designations, setDesignations] = useState([]);
	const [minExperience, setMinExperience] = useState(0);
	const [maxExperience, setMaxExperience] = useState(25);
	const [experience, setExperience] = useState(0);
	const [candidate, setCandidate] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const listRef = useRef(null); 

	useEffect(() => {
		// Retrieve jobs from sessionStorage
		const jobsData = sessionStorage.getItem('jobs');
		const candi= sessionStorage.getItem('candidateId');
		if(candi)
		{
			setCandidate(candi);
		}
		if (jobsData) {
		  try {
			// Parse the jobs data and set it to the state
			const parsedJobs = JSON.parse(jobsData);
			//alert(JSON.stringify(parsedJobs));
			setJobs(parsedJobs);
		  } catch (error) {
			console.error('Error parsing jobs data:', error);
		  }
		}
	  }, []);
	  useEffect(() => {
		const filtered = jobs.filter(job => job.minbasicsalary >= salary);
		setFilteredJobs(filtered);
	  }, [minSalary, maxSalary, jobs]);
	useEffect(() => {
		axios.get(`${BASE_URL}distinct-data`)
		  .then(response => {
			console.log(JSON.stringify(response.data));
			setLocations(response.data.locations);
			setDesignations(response.data.designations);
			setMinExperience(response.data.minExperience);
			setMaxExperience(20);
			setExperience(response.data.minExperience);
		  })
		  .catch(error => {
			console.error("There was an error fetching the data!", error);
		  });
	  }, []);
	  const resetFilters = () => {
		setSelectedDesignations([]);  // Clear selected designations
		setSelectedLocation('');      // Clear selected location
		setExperience(0);             // Clear experience filter
		setFilteredJobs([]);          // Clear filtered jobs
		setLocations([]);             // Clear available locations
	  };
 const handleSalaryChange = (e) => {
		const newSalary = parseFloat(e.target.value);
		const filtered = jobs.filter(job => job.minbasicsalary >= newSalary);
		setFilteredJobs(filtered);
		setSalary(newSalary);
	  };
 const handleExperienceChange = (e) => {
		const newExperience = e.target.value;
		setExperience(newExperience);
		const filtered = jobs.filter(job => job.minimumexperince >= newExperience);
		setFilteredJobs(filtered);
		
};
 const [selectedLocation, setSelectedLocation] = useState(null);
 const [selectedDesignation, setSelectedDesignation] = useState(null);

 const handleLocationChange = (event) => {
	const newLocation = event.target.value;
	
  
	setSalary(0); // Reset salary
	setExperience(0); // Reset experience
	setSelectedLocation(newLocation); 
  if(newLocation==0){
	setFilteredJobs(jobs);

	console.log("Selected Location:");
	}
  else{
	// Corrected filtering logic
	const filtered = jobs.filter(job => job.location_id == newLocation);
	setFilteredJobs(filtered); // Update filtered jobs
  
	// Update selected location
  }
  if (listRef.current) {
	listRef.current.scrollIntoView({ behavior: 'smooth' });
  }
  };
  const [selectedDesignations, setSelectedDesignations] = useState([]);
  const handleDesignationChange = async (event) => {
    const designationId = event.target.value;
    let updatedSelections;

    if (event.target.checked) {
      // Add the designation to the array if checked
      updatedSelections = [...selectedDesignations, designationId];
    } else {
      // Remove the designation from the array if unchecked
      updatedSelections = selectedDesignations.filter(
        (id) => id !== designationId
      );
    }

    setSelectedDesignations(updatedSelections);
    console.log("Selected Designations:", updatedSelections);

    // Submit the updated selections
    await getSearch(updatedSelections, selectedLocation, experience);
  };
// const handleDesignationChange = (event) => {
//     setSelectedDesignation(event.target.value);
//   };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  // Filter designations based on the search term
  const filteredDesignations = designations.filter((designation) =>
    designation.designation.toLowerCase().includes(searchTerm)
  );


  useEffect(() => {
	if (selectedDesignations && selectedDesignations.length > 0) {
	  const designationQuery = selectedDesignations.join(','); // Convert array to comma-separated string
	  axios
		.get(`${BASE_URL}distinct-locations?designation=${designationQuery}`)
		.then(response => {
		  console.log(JSON.stringify(response.data));
		  setLocations(response.data.locations);
		})
		.catch(error => {
		  console.error("There was an error fetching the data!", error);
		});
	}
  }, [selectedDesignations]);

  const getSearch = async (designations, location, experience) => {
	const formData = {
	  skill: designations, // Send multiple skills as an array
	  location: location || [], // Use parameter or empty array
	  exp: experience || 0, // Use parameter or default to 0
	};
  
	setExperience(0);
	setSelectedLocation([]);
  
	console.log("Submitting form data:", formData); // For debugging
  
	try {
	  const response = await fetch(`${BASE_URL}getJobsMulty`, {
		method: "POST",
		headers: {
		  "Content-Type": "application/json",
		},
		body: JSON.stringify(formData),
	  });
  
	  if (response.ok) {
		setStatus(response.status);
		const data = await response.json();
		sessionStorage.setItem("jobs", JSON.stringify(data));
		const jobsData = sessionStorage.getItem("jobs");
		setJobs(JSON.parse(jobsData));
	  } else {
		setStatus(response.status);
		console.error("Failed to submit form");
	  }
	} catch (error) {
	  console.error("Error submitting form", error);
	}
  };
	  const handleButtonClick = async (e) => {
		e.preventDefault();
	
		await getSearch(selectedDesignation,selectedLocation,experience);
	  };
	const calculateDaysAgo = (dateString) => {
		if (!dateString) {
		  return "Invalid date";
		}
	  
		try {
		  const postedDate = parseISO(dateString);
		  return formatDistanceToNow(postedDate, { addSuffix: true });
		} catch (error) {
		  console.error("Error parsing date:", error);
		  return "Invalid date..";
		}
	  };
	  function formatDate(dateString) {
		const date = new Date(dateString);
		const day = date.getDate();
		const month = date.toLocaleString('default', { month: 'long' });
		const year = date.getFullYear();
	  
		// Get the ordinal suffix for the day (e.g., 1st, 2nd, 3rd, etc.)
		const getOrdinalSuffix = (day) => {
		  if (day > 3 && day < 21) return 'th'; // for 11th, 12th, 13th, etc.
		  switch (day % 10) {
			case 1: return 'st';
			case 2: return 'nd';
			case 3: return 'rd';
			default: return 'th';
		  }
		};
	  
		return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
	  } 
	  
	  const concatenateStrings = (str1, str2, str3) => {
		// Filter out falsy values like null, undefined, or empty string
		const parts = [str1, str2, str3].filter(part => part !== null && part !== 'null' && part !== undefined && part !== '');

		//alert(parts);
		return parts.join('/');
	  };
	  const handleBtnClick = async (e,idd) => {
	
		e.preventDefault();
	
	//alert(JSON.stringify(formData));
	if(candidate>0){
	const formData={
		jobId: idd,
		candidateId:candidate
	  };
	 // alert(JSON.stringify(formData));
		try {
			
		  //const response = await fetch('http://localhost:8000/api/getJobDetails', {
		  const response = await fetch(`${BASE_URL}saveBookmark`, {
			
		 	method: 'POST',
		 	headers: {
		 	  'Content-Type': 'application/json'
		 	},
			 body: JSON.stringify(formData)
		   });
		   const data = await response.json();
		   if (response.ok) {
		
		 	
			 sessionStorage.setItem('bookMarkdetails', JSON.stringify(data));
			 toast.success("Saved this Jobs To Bookmark");
			
		   } else {
			console.error('Failed to submit form:', data.message || response.statusText);
			toast.error(data.message || 'Failed to save the bookmark. Please try again.');
		   }
		} catch (error) {
			console.error('Error submitting form:', error);
			toast.error('An error occurred. Please try again later.');
		}
		}else{
			toast.success("Please Login or SignUp!");	
		}
	  };
	 const handleClick = async (e,idd,desigid) => {
	
		e.preventDefault();
		//alert(idd);
	//alert(JSON.stringify(formData));

	const formData={
		jobId: idd,
		designationId: desigid,
		candidateId:candidate
	  };
	 // alert(JSON.stringify(formData));
		try {
			
		  //const response = await fetch('http://localhost:8000/api/getJobDetails', {
		  const response = await fetch(`${BASE_URL}getJobDetails`, {
			
		 	method: 'POST',
		 	headers: {
		 	  'Content-Type': 'application/json'
		 	},
			 body: JSON.stringify(formData)
		   });
		   if (response.ok) {
		
		 	const data = await response.json();
			 sessionStorage.setItem('jobdetails', JSON.stringify(data));
			navigate('/jobdetails', { state: { response: data } });
			
		   } else {
		 	console.error('Failed to submit form');
		   }
		} catch (error) {
		  console.error('Error submitting form', error);
		}
	  };
	const renderResponse = () => {
		if (status === 404) {
			return <div>No job openings found..</div>;
		  }
		if (Array.isArray(filteredJobs)) {
		  return filteredJobs.map((item, index) => (
			<div key={index}>
			 
			  <div className="card mb-3">
						<div className="card-body jobsearchview">
                        
							<div className="row">
								<div className="col-lg-10">
									
									<h4>{item.designation}</h4>
									{item.flag_emp === 1 && (
									 <h5 className="mb-3">{item.companyname}</h5> 
									 
									)}
									<div className="deatilssec"><span className="pe-3"><i className="fa fa-briefcase" aria-hidden="true"></i> {item.minimumexperince} - {item.maximumexperince} years of experience - {item.industryname}  </span> <span className="pe-3"> {item.currency} {item.minbasicsalary} - {item.maxbasicsalary} /Month </span> </div>
									<div className="deatilssec"><span className="pe-3"> <i className="fa fa-map-marker" aria-hidden="true"></i>{item.location_name}</span></div>
									<div className="deatilssec"><span className="pe-3"><i className="fa fa-thumb-tack" aria-hidden="true"></i> {concatenateStrings(`${item.requiredqualification1}`,`${item.requiredqualification2}`,`${item.requiredqualification3}`)} </span></div>
									
								</div>
								{item.flag_emp === 1 && (
								<div className="col-lg-2">
									<div className="companylogo">
										<img src={`https://seagulljobs4u.com/admin/uploads/images/companylogos/${item.company_logo}`} alt="" className="img-fluid" />
									</div>
								</div> 
							)}
							</div>
							<div className="row mt-3">
								<div className="col-lg-6">
								<h5>Posted On: {formatDate(`${item.posteddate}`)}  | Openings: {item.numberofposts} </h5>
								</div>
								<div className="col-lg-6 text-right">
									<a href="javascript:void(0);" onClick={(e) => handleClick(e, item.jobidd,item.designationid)}>
									<small className="pe-3"> <i class="fa fa-arrow-right arrowbox" ></i> View Details</small>
									</a>
									<li class="list-inline-item"><a href="javascript:void(0)" onClick={(e) => handleBtnClick(e, item.jobidd)}  class="btn btn-icon btn-sm btn-soft-primary"><i class="fa fa-bookmark"></i></a></li>
								</div>

							</div>
                           
							<h5>&nbsp;</h5>
                                <ShareButtons  jobid={item.jobidd} />
						</div>
					</div>
			</div>
		  ));
		}  else {
		  return <p>Invalid data format received</p>;
		}
	  };
	  const vertic={
		maxHeight: '300px', 
		overflowY:'scroll',  
		padding: '10px', 

	  }
  return (
    <div>
        <Header />
        <section className="practise-part section-p bg-light">
        <div className="container">
			<div className="row">
			    <div className="col-lg-3">
					<div className="card mb-3">
						<div className="card-body">
							<h4 className="mb-2">Refined Search</h4>
							<button onClick={resetFilters}>Reset Filters</button>
								<div className="list-group-select">
									<h5>Designations</h5>
									<input
										type="text"
										placeholder="Type to search..."
										value={searchTerm}
										onChange={handleSearchChange}
										style={{
										marginBottom: "10px",
										padding: "5px",
										width: "100%",
										boxSizing: "border-box",
										}}
									/>
									{/* <div className="facet-wrapper1" data-facet="district" style={vertic} > 
									{designations.map(designation => (
										 <label key={designation.designationid} className="checkBox-wrap" htmlFor={designation.designation}>
										 {designation.designation}(<span style={{fontWeight:"500"}}>{designation.posts}</span>)
										 <input
										   type="radio"
										   id={designation.designation}
										   value={designation.designationid}
										   onChange={handleDesignationChange}
										   checked={selectedDesignation === designation.designationid.toString()}
										   data-el="facet"
										   name="designation"
										 />
										 <span className="checkmark"></span>
									   </label>
										))}
										
									</div> */}
	<div
      className="facet-wrapper1"
      data-facet="district"
      style={{ maxHeight: "300px", overflowY: "auto" }} // Optional scroll for long lists
    >
      {filteredDesignations.length > 0 ? (
        filteredDesignations.map((designation) => (
          <label
            key={designation.designationid}
            className="checkBox-wrap"
            htmlFor={designation.designation}
          >
            {designation.designation} (
            <span style={{ fontWeight: "500" }}>{designation.posts}</span>)
            <input
              type="checkbox"
              id={designation.designation}
              value={designation.designationid}
              onChange={handleDesignationChange}
              checked={selectedDesignations.includes(designation.designationid.toString())}
              data-el="facet"
              name="designation"
            />
            <span className="checkmark"></span>
          </label>
        ))
      ) : (
        <p>No matching designations found.</p>
      )}
    </div>
									<hr />
									<h5>Experience</h5>
									<div>
										<small>Current Value: {experience} yrs</small>
									</div>
									<input
										name="range-2"
										value={experience}
										min={minExperience}
										max={maxExperience}
										step="1"
										type="range"
										style={{ width: "100%" }}
										onChange={handleExperienceChange} 
									/>
									 <div className="d-flex row">
										<small className="col-lg-6 text-left">{minExperience} yrs</small>
										<small className="col-lg-6 text-right">{maxExperience} yrs</small>
									</div>
									<hr />

									<h5>Salary</h5>
						
									<div>
										<small>Minimum Salary: {salary} /Month</small>
									</div>
									<input
										name="range-3"
										value={salary}
										min={minSalary}
										max={maxSalary}
										step=".25"
										type="range"
										style={{ width: "100%" }}
										onChange={handleSalaryChange}
									/>
									 <div className="d-flex row">
										<small className="col-lg-6 text-left">{minSalary} /Month</small>
										<small className="col-lg-6 text-right">{maxSalary} /Month</small>
									</div>
						
									<hr />
									<h5>Locations 
									</h5>
										<div className="facet-wrapper1" style={vertic} data-facet="district"> 
										<label key='0'  className="checkBox-wrap" >All
											<input type="radio" 
											 data-el="facet" 
											 id="All"
											 value="0"
											 onChange={handleLocationChange}
											 name="location" 
											 checked={selectedLocation === "0"}
											 />
											 <span className="checkmark"></span> 
											</label>
											
										{locations.map(location => (
										
												<label key={location.location_id}  className="checkBox-wrap" for={location.location_name}>{location.location_name}
											<input type="radio" 
											 data-el="facet" 
											 id={location.location_name}
											 value={location.location_id}
											 onChange={handleLocationChange}
											 name="location" 
											  checked={selectedLocation === location.location_id.toString()}
											 />
											 <span className="checkmark"></span> 
											</label>
											
											))}

										
										</div>
		
											
								</div>
				
						</div>
					</div>
				</div>
				<div className="col-lg-9">
					<div className="row mb-3">
						{/* <div className="col-lg-4">
							<p>1 - 20 of 43690 Search result</p>
						</div> */}
						{/* <div className="col-lg-12">
						
									<div>
										<small>Minimum Salary: {salary} /Month</small>
									</div>
									<input
										name="range-3"
										value={salary}
										min={minSalary}
										max={maxSalary}
										step=".25"
										type="range"
										style={{ width: "100%" }}
										onChange={handleSalaryChange}
									/>
									 <div className="d-flex row">
										<small className="col-lg-6 text-left">{minSalary} /Month</small>
										<small className="col-lg-6 text-right">{maxSalary} /Month</small>
									</div>
						</div> */}
						
						{/* <div className="col-lg-4">
							<select className="btn btn-defult border-1 form-contorl text-left w-100">
								<option selected>Sort By Relavence</option>
								<option value="3">Three</option>
								<option value="1">One</option>
								<option value="0">Zero</option>
								<option value="2">Two</option>
								<option value="8">Eight</option>
							</select>
						</div> */}
					</div>


{jobs ? (
        <div>
			<Toaster toastOptions={{ duration: 4000 }} />
		<div ref={listRef}>
          {renderResponse()}
		  </div>
        </div>
      ) : (
        <p>No data received</p>
      )}

					

{/* {response ? (
        <div>
          <h2>Data received:</h2>
          <pre>{JSON.stringify(response, null, 2)}</pre>
        </div>
      ) : (
        <p>No data received</p>
      )}  */}
					
					
				</div>
				
			</div>
		</div>
    </section>
    <Footer />
    </div>
  )
}

export default JobSearch