import React, { useState,useEffect } from 'react';
import { Link ,Routes,Route,Router,Switch, useNavigate, useHistory } from 'react-router-dom';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
const Faq = () => {
    return (
        <div>
            <body className="home-3">
            <Header />

    <div class="about-4-part section-p-2">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-12 text-center">
                    <div class="section-head">
                        <h2>Frequently Asked Question</h2>
                    </div>
                </div>
                </div>
     <div class="row justify-content-center">
       <div class="col-lg-6">  
                    <div class="about-4-des">
                    <h4 className='mb-3'>General Questions</h4>
                        <div id="accordion-4" class="about-4-accodian">
                            <div class="accodian-4-item active">
                                <div class="accodian-4-head" data-toggle="collapse" data-target="#collapse-4-Two" >
                                    <h5 class="bold">Q1: What is SeagullJobs4U.com?</h5>
                                </div>
                                <div id="collapse-4-Two" class="accodian-4-result collapse show" data-parent="#accordion-4">
                                    <p>A: SeagullJobs4U.com is an online platform where candidates can explore and apply for
various overseas job opportunities. It offers a user-friendly interface for job seekers to
register, update their profiles, and apply for suitable positions.</p>
                                </div>
                            </div>
                            <div class="accodian-4-item active">
                                <div class="accodian-4-head" data-toggle="collapse" data-target="#collapse-4-One" >
                                    <h5 class="bold">Q2: Who can use SeagullJobs4U.com?</h5>
                                </div>
                                <div id="collapse-4-One" class="accodian-4-result collapse show" data-parent="#accordion-4">
                                    <p>A: The platform is designed for job seekers looking for overseas employment opportunities in
industries such as Oil & Gas, Construction, Telecommunication, IT, Pharma, Hospitality,
Healthcare, Ports, Shipping, and Logistics.</p>
                                </div>
                            </div>
                            
                            <div class="accodian-4-item active" >
                                <div class="accodian-4-head" data-toggle="collapse" data-target="#collapse-4-three" >
                                    <h5 class="bold">Q3: Is SeagullJobs4U.com free to use? </h5>
                                </div>
                                <div id="collapse-4-three" class="accodian-4-result collapse show" data-parent="#accordion-4">
                                    <p>A: Yes, creating an account, searching for jobs, and applying to jobs on SeagullJobs4U.com is
                                    completely free for candidates.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                <br></br>
                    <div class="about-4-des">
                        <h4 className='mb-3'>Registration and Login</h4>
                        <div id="accordion-4" class="about-4-accodian">
                            <div class="accodian-4-item active">
                                <div class="accodian-4-head" data-toggle="collapse" data-target="#collapse-4-Two" >
                                    <h5 class="bold">Q4: How do I register on SeagullJobs4U.com?</h5>
                                </div>
                                <div id="collapse-4-Two" class="accodian-4-result collapse show" data-parent="#accordion-4">
                                    <p>A: To register:<br/>
1. Visit SeagullJobs4U.com.<br/>
2. Click on the Sign Up button.<br/>
3. Provide your email address and phone number.<br/>
4. Verify your email and phone via the OTP sent to you.<br/>
5. Complete your profile details to start applying for jobs.</p>
                                </div>
                            </div>
                            <div class="accodian-4-item active">
                                <div class="accodian-4-head" data-toggle="collapse" data-target="#collapse-4-One" >
                                    <h5 class="bold">Q5: What if I forget my password?</h5>
                                </div>
                                <div id="collapse-4-One" class="accodian-4-result collapse show" data-parent="#accordion-4">
                                    <p>A: If you forget your password:<br/>
1. Go to the Login page and click on Forgot Password.<br/>
2. Enter your registered email address.<br/>
3. Follow the instructions sent to your email to reset your password.</p>
                                </div>
                            </div>
                            
                             <div class="accodian-4-item active">
                                <div class="accodian-4-head" data-toggle="collapse" data-target="#collapse-4-three" >
                                    <h5 class="bold">Q6: Can I log in with my phone number?</h5>
                                </div>
                                <div id="collapse-4-three" class="accodian-4-result collapse show" data-parent="#accordion-4">
                                    <p>A: Yes, you can log in using your registered phone number and the OTP sent to it.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <br></br>


                    <div class="about-4-des">
                        <h4 className='mb-3'>Profile and Job Application</h4>
                        <div id="accordion-4" class="about-4-accodian">
                            <div class="accodian-4-item active">
                                <div class="accodian-4-head" data-toggle="collapse" data-target="#collapse-4-Two" >
                                    <h5 class="bold">Q7: How do I create my profile?</h5>
                                </div>
                                <div id="collapse-4-Two" class="accodian-4-result collapse show" data-parent="#accordion-4">
                                    <p>A: After registering, log in to your account, go to the Profile section, and fill in your details,
including:<br/>
• Personal Information<br/>
• Education and Work Experience<br/>
• Skills and Certifications<br/>
• Passport and Visa Details (if applicable)</p>
                                </div>
                            </div>
                            <div class="accodian-4-item active">
                                <div class="accodian-4-head" data-toggle="collapse" data-target="#collapse-4-One" >
                                    <h5 class="bold">Q8: How can I apply for jobs?</h5>
                                </div>
                                <div id="collapse-4-One" class="accodian-4-result collapse show" data-parent="#accordion-4">
                                    <p>A: To apply for a job:<br/>
1. Log in to your account.<br/>
2. Browse or search for jobs that match your skills.<br/>
3. Click on the Apply button for the desired job.<br/>
4. Your application will be submitted, and you can track it in the Applied Jobs section.</p>
                                </div>
                            </div>
                            
                             <div class="accodian-4-item active">
                                <div class="accodian-4-head" data-toggle="collapse" data-target="#collapse-4-three" >
                                    <h5 class="bold">Q9: Can I see the jobs I’ve applied for?</h5>
                                </div>
                                <div id="collapse-4-three" class="accodian-4-result collapse show" data-parent="#accordion-4">
                                    <p>A: Yes, all your applied jobs are listed in the Applied Jobs section of your dashboard. You can
                                    view the status of each application here.</p>
                                </div>
                            </div>

                            <div class="accodian-4-item active">
                                <div class="accodian-4-head" data-toggle="collapse" data-target="#collapse-4-three" >
                                    <h5 class="bold">Q10: How often should I update my profile?</h5>
                                </div>
                                <div id="collapse-4-three" class="accodian-4-result collapse show" data-parent="#accordion-4">
                                    <p>A: It’s recommended to update your profile regularly, especially when you gain new
                                    qualifications, certifications, or work experience. Make sure all details are accurate and upto-date, including your passport information.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <br></br>


                   
</div>
<div className='col-lg-6'>
<div class="about-4-des">
                        <h4 className='mb-3'>Data Security and Privacy</h4>
                        <div id="accordion-4" class="about-4-accodian">
                            <div class="accodian-4-item active">
                                <div class="accodian-4-head" data-toggle="collapse" data-target="#collapse-4-Two" >
                                    <h5 class="bold">Q11: Is my data secure on SeagullJobs4U.com?</h5>
                                </div>
                                <div id="collapse-4-Two" class="accodian-4-result collapse show" data-parent="#accordion-4">
                                    <p>A: Absolutely. SeagullJobs4U.com employs advanced security measures to ensure your data is
100% secure. The website is also fully GDPR-compliant, guaranteeing that your personal
information is handled with the utmost confidentiality.</p>
                                </div>
                            </div>
                            <div class="accodian-4-item active">
                                <div class="accodian-4-head" data-toggle="collapse" data-target="#collapse-4-One" >
                                    <h5 class="bold">Q12: Will my information be shared with third parties?</h5>
                                </div>
                                <div id="collapse-4-One" class="accodian-4-result collapse show" data-parent="#accordion-4">
                                    <p>A: Your personal data is only shared with employers for recruitment purposes and is not
                                    disclosed to unauthorized third parties. For more details, refer to our Privacy Policy.</p>
                                </div>
                            </div>
                            
                             <div class="accodian-4-item active">
                                <div class="accodian-4-head" data-toggle="collapse" data-target="#collapse-4-three" >
                                    <h5 class="bold">Q13: How can I delete my account?</h5>
                                </div>
                                <div id="collapse-4-three" class="accodian-4-result collapse show" data-parent="#accordion-4">
                                    <p>A: If you wish to delete your account, please contact our support team via the Contact
                                    Us page. Once your account is deleted, all associated data will be removed permanently.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="about-4-des">
                        <h4 className='mb-3'>Technical and Support
                        </h4>
                        <div id="accordion-4" class="about-4-accodian">
                            <div class="accodian-4-item active">
                                <div class="accodian-4-head" data-toggle="collapse" data-target="#collapse-4-Two" >
                                    <h5 class="bold">Q14: What should I do if I encounter issues with the website?</h5>
                                </div>
                                <div id="collapse-4-Two" class="accodian-4-result collapse show" data-parent="#accordion-4">
                                    <p>A: If you face any technical issues, please contact our support team through the Help &
                                    Support section or email us at support@seagulljobs4u.com</p>
                                </div>
                            </div>
                            <div class="accodian-4-item active">
                                <div class="accodian-4-head" data-toggle="collapse" data-target="#collapse-4-One" >
                                    <h5 class="bold">Q15: Is there a mobile app for SeagullJobs4U.com?</h5>
                                </div>
                                <div id="collapse-4-One" class="accodian-4-result collapse show" data-parent="#accordion-4">
                                    <p>A: Currently, SeagullJobs4U.com is optimized for both desktop and mobile browsers. A
dedicated mobile app is under development and will be launched soon.
</p>
                                </div>
                            </div>
                            
                             <div class="accodian-4-item active">
                                <div class="accodian-4-head" data-toggle="collapse" data-target="#collapse-4-three" >
                                    <h5 class="bold">Q16: What are the recommended browsers for using SeagullJobs4U.com?</h5>
                                </div>
                                <div id="collapse-4-three" class="accodian-4-result collapse show" data-parent="#accordion-4">
                                    <p>A: The platform is best experienced on the latest versions of Google Chrome, Mozilla Firefox,
                                    Microsoft Edge, or Safari.</p>
                                </div>
                            </div>
                        </div>
                    </div>
<br></br>


                    <div class="about-4-des">
                        <h4 className='mb-3'>Additional Features
                        </h4>
                        <div id="accordion-4" class="about-4-accodian">
                            <div class="accodian-4-item active">
                                <div class="accodian-4-head" data-toggle="collapse" data-target="#collapse-4-Two" >
                                    <h5 class="bold">Q17: Can I receive job alerts?</h5>
                                </div>
                                <div id="collapse-4-Two" class="accodian-4-result collapse show" data-parent="#accordion-4">
                                    <p>A: Yes, you can enable job alerts in your profile settings to receive notifications about new job
                                    postings matching your profile.</p>
                                </div>
                            </div>
                            <div class="accodian-4-item active">
                                <div class="accodian-4-head" data-toggle="collapse" data-target="#collapse-4-One" >
                                    <h5 class="bold">Q18: Can I save jobs to apply for later?</h5>
                                </div>
                                <div id="collapse-4-One" class="accodian-4-result collapse show" data-parent="#accordion-4">
                                    <p>A: Yes, you can save jobs to your favourites and apply at a later time. Saved jobs can be
                                    accessed from your dashboard.
</p>
                                </div>
                            </div>
                            
                             <div class="accodian-4-item active">
                                <div class="accodian-4-head" data-toggle="collapse" data-target="#collapse-4-three" >
                                    <h5 class="bold">Q19: How do I update my passport details?</h5>
                                </div>
                                <div id="collapse-4-three" class="accodian-4-result collapse show" data-parent="#accordion-4">
                                    <p>A: Log in to your account, navigate to the Profile section, and update your passport details
                                    under the relevant field.</p>
                                </div>
                            </div>
                            <div class="accodian-4-item active">
                                <div class="accodian-4-head" data-toggle="collapse" data-target="#collapse-4-four" >
                                    <h5 class="bold">Q20: How do I know if a job is genuine?
                                    </h5>
                                </div>
                                <div id="collapse-4-four" class="accodian-4-result collapse show" data-parent="#accordion-4">
                                    <p>A: All jobs posted on SeagullJobs4U.com are verified to ensure authenticity. Be cautious of
                                    any communication requesting payments or sensitive information outside the platform.</p>
                                </div>
                            </div>
                        </div>
                    </div><br></br>


                    <div class="about-4-des">
                        <h4 className='mb-3'>Contact Us
                        </h4>
                        <div id="accordion-4" class="about-4-accodian">
                            <div class="accodian-4-item active">
                                <div class="accodian-4-head" data-toggle="collapse" data-target="#collapse-4-Two" >
                                    <h5 class="bold">Q21: How can I contact customer support?</h5>
                                </div>
                                <div id="collapse-4-Two" class="accodian-4-result collapse show" data-parent="#accordion-4">
                                    <p>A: You can reach us through the Contact Us page, email us at support@seagulljobs4u.com, or
                                    call our helpline at +91-XXXX-XXXXXX during business hours.</p>
                                </div>
                            </div>
                            <div class="accodian-4-item active">
                                <div class="accodian-4-head" data-toggle="collapse" data-target="#collapse-4-One" >
                                    <h5 class="bold">Q22: Where can I provide feedback or suggestions?</h5>
                                </div>
                                <div id="collapse-4-One" class="accodian-4-result collapse show" data-parent="#accordion-4">
                                    <p>A: We welcome your feedback! Use the Feedback section on the website or email us
                                    at feedback@seagulljobs4u.com.
</p>
                                </div>
                            </div>
                            
                             
                        </div>
                    </div>

                    </div> </div>
                </div>
                 
            </div>
       
        <Footer />
        </body>
       
        </div>
      )
}
export default Faq