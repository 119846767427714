import React, { useState, useEffect } from 'react';
import { Link ,Routes,Route,Router,Switch, useNavigate, useHistory , useLocation } from 'react-router-dom';
import { formatDistanceToNow, parseISO } from 'date-fns';
import axios from 'axios';
import Header from './Header';
import Footer from './Footer';
import ShareButtons from './components/ShareButtons';
const BASE_URL=process.env.REACT_APP_BASE_URL;
const ShareDetails = () => {
    const location = useLocation();
	const navigate = useNavigate();
//	const { response } = location.state || {};
const [errorData, setErrorData] = useState('');
const [successData, setSuccessData] = useState('');
	const [jobDetails, setJobDetails] = useState([]);
	const [loginApply, setLoginApply] = useState('Login To Apply');
	const [regApply, setRegApply] = useState('Register To Apply');
	const [regDisply, setRegDisply] = useState('block');
	const [prime, setPrime] = useState([]);
	const [jobid, setJobid] = useState('');
	const [applied, setApplied] = useState(0);
	const [candidate, setCandidate] = useState(0);
    const [jobId, setJobId] = useState(null);
    useEffect(() => {
        const fetchJobDetails = async () => {
            const queryParams = new URLSearchParams(location.search);
            const job = queryParams.get('job');
            if (job) {
              setJobId(job);
      
              const formData = {
                jobId: job,
                designationId: 0,
		        candidateId:0
              };
      
              try {
                const response = await fetch(`${BASE_URL}getShareDetails`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(formData)
                });
      
                if (response.ok) {
                  const data = await response.json();
                 console.log(data);
                 setJobDetails(data);
                  
                } else {
                  console.error('Failed to submit form');
                }
              } catch (error) {
                console.error('Error submitting form', error);
              }
            }
			
			
          };
      
          fetchJobDetails();
    }, [location]);
	useEffect(() => {
		// Retrieve jobs from sessionStorage
		const login1 = sessionStorage.getItem('login');
		if(login1==='yes'){
			setLoginApply('Apply');
			setRegDisply('none')
		}
		else{
			setLoginApply('Login To Apply');
			setRegApply('Register To Apply')
			setRegDisply('block')
		}
	}, []);
	useEffect(() => {
		axios
        .get(`${BASE_URL}getprimeJobs`)        
        .then((response) => {
         // alert(JSON.stringify(response.data));
         setPrime(response.data);
        })
        .catch((error) => {
          console.error('Error fetching data:', error); 
        });
	}, []);
	useEffect(() => {
		// Retrieve jobs from sessionStorage
		const jobsData = sessionStorage.getItem('jobdetails');
		const candi= sessionStorage.getItem('candidateId');
		if(candi)
		{
			setCandidate(candi);
		}
		if (jobsData) {
		  try {
			// Parse the jobs data and set it to the state
			const parsedJobs = JSON.parse(jobsData);
			setJobDetails(parsedJobs);
			//console.log(parsedJobs); 
			setJobid(parsedJobs[0].jobidd);
		  } catch (error) {
			console.error('Error parsing jobs data:', error);
		  }
		}
	  }, []);
	  useEffect(() => {
		if(candidate){
		axios
        .get(`${BASE_URL}getmyJobs?candidateId=${candidate}`)        
        .then((response) => {
         // alert(JSON.stringify(response.data));
         if (response.data && response.data.length > 0) {
         setPrime(response.data);
         }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
	}
	}, [candidate]);
	  useEffect(() => {
		if (jobid) {  // Ensure jobid is not undefined or null
		  axios
			.get(`${BASE_URL}getCountApplies`, {
			  params: { jobid: jobid }  // Pass jobid as a query parameter
			})
			.then((response) => {
			  // alert(JSON.stringify(response.data));
			  setApplied(response.data.applicantCount);
			})
			.catch((error) => {
			  console.error('Error fetching data:', error);
			});
		}
	  }, [jobid]);
	const calculateDaysAgo = (dateString) => {
		if (!dateString) {
		  return "Invalid date";
		}
	  
		try {
		  const postedDate = parseISO(dateString);
		  return formatDistanceToNow(postedDate, { addSuffix: true });
		} catch (error) {
		  console.error("Error parsing date:", error);
		  return "Invalid date..";
		}
	  };
	  const concatenateStrings = (str1, str2, str3) => {
		// Filter out falsy values like null, undefined, or empty string
		const parts = [str1, str2, str3].filter(part => part !== null && part !== 'null' && part !== undefined && part !== '');

		//alert(parts);
		return parts.join('/');
	  };
	  const handleButtonClick = async (jobId) => {
		
		const candidateId = sessionStorage.getItem('candidateId');
		//alert(jobId);
		if (candidateId) {

			const formData={
				jobopeningid: jobId,
				candidateid: candidateId
			  };
		  // User is logged in, proceed to apply for the job
		  try {
			//const response = await fetch('http://localhost:8000/api/applyJob',{
			const response = await fetch(`${BASE_URL}applyJob`,{
			 method: 'POST',
		 	headers: {
		 	  'Content-Type': 'application/json'
		 	},
			 body: JSON.stringify(formData)
		   });
	
			if (response.status === 200) {
				const data1 = await response.json();
				setSuccessData('Job application successful. Acknowledge Number: ' + data1.acknowledge);
				setLoginApply('Successfully Applied ');
				setErrorData("");
				if (data1) {
					setSuccessData('Job application successful. Acknowledge Number: ' + data1.acknowledge);
					
				  } else {
					console.error('Unexpected response structure:', response.error);
					setSuccessData('Job application successful, but unable to retrieve acknowledge number.');
					setLoginApply('Successfully Applied ');
					setErrorData("");
				  }
			} else {
				setErrorData('You have Already Applied for this job');
				setLoginApply('Already Applied ');
				setSuccessData("");
			}
		  } catch (error) {
			setErrorData('Error applying for the job:', error);
			setLoginApply('Some Error..');
			setSuccessData("");
			//alert('An error occurred while applying for the job');
		  }
		} else {
		  // User is not logged in, redirect to login page
		  navigate('/login', { state: { jobId } });
		}
	  };
	  const handleClick = async (e,idd,desigid) => {
	
		e.preventDefault();
	//alert(JSON.stringify(formData));
	const formData={
		jobId: idd,
		designationId: desigid,
		candidateId:candidate
	  };
	 // alert(JSON.stringify(formData));
		try {
			
		  //const response = await fetch('http://localhost:8000/api/getJobDetails', {
		  const response = await fetch(`${BASE_URL}getJobDetails`, {
			
		 	method: 'POST',
		 	headers: {
		 	  'Content-Type': 'application/json'
		 	},
			 body: JSON.stringify(formData)
		   });
		   if (response.ok) {
		
		 	const data = await response.json();
			 sessionStorage.setItem('jobdetails', JSON.stringify(data));
			 const jobsData = sessionStorage.getItem('jobdetails');

			setJobDetails(JSON.parse(jobsData));
		//	navigate('/jobdetails', { state: { response: data } });
			
		   } else {
		 	console.error('Failed to submit form');
		   }
		} catch (error) {
		  console.error('Error submitting form', error);
		}
	  };
	  const spanStyle={
		backgroundColor: '#e0f7fa', /* Change this to your desired background color */
		color: '#00796b',/* Text color */
		padding: '5px 15px', /* Padding to make the element oval */
		borderRadius: '20px', /* Large border radius for oval shape */
		display: 'inline-block', /* Inline block to maintain the text's natural size */
		fontWeight: 'bold' /* Optional: Make text bold */
	};
	const vertic={
		maxHeight: '800px', 
		overflowY:'scroll',  
		padding: '10px', 

	  }
	  const SkillsDisplay = ({ keySkills }) => {
		// Split the keySkills string into an array
		//const skillsArray = keySkills.split(', ');
	    const skillsArray = keySkills ? keySkills.split(',') : [];
		return (
		  <div>
			<div  className="searchlistskill mt-1">
			{skillsArray.map((skill, index) => (
			
					
						<div key={index} className="searchitem">
							<a  className="Searchitemsview">{skill}</a>
						</div>
			  	
				



			))}
	</div>
		  </div>
		);
	  };
	  
	  function formatDate(dateString) {
		const date = new Date(dateString);
		const day = date.getDate();
		const month = date.toLocaleString('default', { month: 'long' });
		const year = date.getFullYear();
	  
		// Get the ordinal suffix for the day (e.g., 1st, 2nd, 3rd, etc.)
		const getOrdinalSuffix = (day) => {
		  if (day > 3 && day < 21) return 'th'; // for 11th, 12th, 13th, etc.
		  switch (day % 10) {
			case 1: return 'st';
			case 2: return 'nd';
			case 3: return 'rd';
			default: return 'th';
		  }
		};
	  
		return `${day}${getOrdinalSuffix(day)} ${month} ${year}`;
	  } 
	  
	  const renderResponse = () => {
        if (jobDetails) {
		  return (
			<div >
				<div className="card mb-3">
						<div className="card-body">
							<div className="row">
								<div className="col-lg-10">
									<h4>{jobDetails.designation} - ({jobDetails.numberofposts}Posts)</h4>
                                    {jobDetails.flag_emp === 1 && (
									<h5 className="mb-3">{jobDetails.companyname}</h5> 
                                    )}
									<div className="deatilssec"><span className="pe-3"><i className="fa fa-briefcase" aria-hidden="true"></i> {jobDetails.minimumexperince} - {jobDetails.maximumexperince} years of experience in {jobDetails.industryname}  </span>  </div>
									<div className="deatilssec"><span className="pe-3"><i className="fa fa-dollar-sign" aria-hidden="true"></i> {jobDetails.currency} {jobDetails.minbasicsalary} - {jobDetails.maxbasicsalary} /Month </span> </div>
									<div className="deatilssec"><span className="pe-3"> <i className="fa fa-map-marker" aria-hidden="true"></i>{jobDetails.location_name}</span></div>
									<div className="deatilssec"><span className="pe-3"><i className="fa fa-file" aria-hidden="true"></i> {concatenateStrings(`${jobDetails.requiredqualification1}`,`${jobDetails.requiredqualification2}`,`${jobDetails.requiredqualification3}`)} </span></div>
									
								</div>
                                {jobDetails.flag_emp === 1 && (
								 <div className="col-lg-2">
									<div className="companylogo">
										<img src={`https://seagulljobs4u.com/admin/uploads/images/companylogos/${jobDetails.company_logo}`} alt="" className="img-fluid" />
									</div>
								</div> 
                                )}
							</div>
							<hr />
							<div className="row">
								<div className="col-lg-6">
								{/* <h5>Posted {formatDate(`${jobDetails.posteddate}`)}  <br /> Openings: {jobDetails.numberofposts} | Applicants: {applied} </h5> */}
									<h5>Posted On {formatDate(`${jobDetails.posteddate}`)}  <br /> Openings: {jobDetails.numberofposts}  </h5>
								</div>
								<div className="col-lg-3" style={{display:regDisply}}>
									<a href="/signup"><button className="btn btn-pink mb-2 w-100">{regApply}</button></a>
								</div>
								{/* <div className="col-lg-3" style={{display:regDisply}}>
									<a href="/signup"><button className="btn btn-pink mb-2 w-100" onClick={() => handleButtonClick(jobDetails.jobidd)}>{loginApply}</button></a>
								</div> */}
									{jobDetails.acknowledge_no ? (
										<div className="col-lg-6">
										<span style={{fontWeight:"bold"}}>You have already Applied this job <br /> Your Acknowledge Number is : {jobDetails.acknowledge_no}</span>
										</div>
									) : (
										<div className="col-lg-3">
										<a href="javascript:void(0);">
										<button
											className="btn btn-blue mb-2 w-100"
											onClick={() => handleButtonClick(jobDetails.jobidd)}
										>
											{loginApply}
										</button>
										</a>
										
										</div>
									)}
									<br />
									<h5 className="text-center mb-3 pb-3" style={{color:"red",paddingLeft:"20px"}}>{errorData}</h5>
									<h5 className="text-center mb-3 pb-3" style={{color:"green",paddingLeft:"20px"}}>{successData}</h5>
							</div>
						</div>
					</div>
					
					
					
					
					<div className="card mb-3">
						<div className="card-body">
							<div className="mb-3">
								<h4>Job Description</h4>
								<h5>
								{jobDetails.jobtitle?.length > 0 ? "Code: "+ jobDetails.jobtitle : " "}
                                </h5>
								<h5>
								{jobDetails.descriptions?.length > 0 ? jobDetails.descriptions : " "}
                                </h5>
								
							</div>
							
				
							{/* {jobDetails.required_profile && (
							<div className="mb-3">
								<h4>Required Candidate Profile</h4>
								<h5>
								{jobDetails.required_profile}
								</h5>
							</div>
							)} */}
							<div className="mb-3">
								{/* <h5><b>Role : </b>{jobDetails.jobrole} </h5> */}
								<h5><b>Industry : </b>{jobDetails.industryname} </h5>
								<h5><b>Department : </b>{jobDetails.departmentname} </h5>
								<h5><b>Job Type : </b>{jobDetails.employment_type}</h5> 
								 <h5><b>Employment Type : </b>{jobDetails.jobtype}</h5>
								<h5><b>Education : </b>{concatenateStrings(`${jobDetails.requiredqualification1}`,`${jobDetails.requiredqualification2}`,`${jobDetails.requiredqualification3}`)} </h5>
							</div>
							
							<div className="mb-3">
							{jobDetails.professional_skills && jobDetails.professional_skills.length > 0 && (
								<>
									<h4>Professional Skills</h4>
							
									<SkillsDisplay keySkills={jobDetails.professional_skills} />
								</>
								)}
								{jobDetails.languages && jobDetails.languages.length > 0 && (
								<>
									<h4>Languages</h4>
								
									<SkillsDisplay keySkills={jobDetails.languages} />
								</>
								)}
								{jobDetails.behavioural_skills && jobDetails.behavioural_skills.length > 0 && (
								<>
									<h4>Behavioural Skills</h4>
								
									<SkillsDisplay keySkills={jobDetails.behavioural_skills} />
								</>
								)}
								<h5>&nbsp;</h5>

                                <h5>&nbsp;</h5>
                                <ShareButtons  jobid={jobDetails.jobidd} />
								{/* <div className="searchlist mt-3">

									<div className="searchitem">
										<a href="#!" className="Searchitemsview">Welders</a>
									</div>
									<div className="searchitem">
										<a href="#!" className="Searchitemsview">Fiters</a>
									</div>
									<div className="searchitem">
										<a href="#!" className="Searchitemsview">Accounts</a>
									</div>
									<div className="searchitem">
										<a href="#!" className="Searchitemsview">Project Managers</a>
									</div>
									<div className="searchitem">
										<a href="#!" className="Searchitemsview">Riggers</a>
									</div>
									<div className="searchitem">
										<a href="#!" className="Searchitemsview">Designers</a>
									</div>
									<div className="searchitem">
										<a href="#!" className="Searchitemsview">Engineres</a>
									</div>
									
									
									<div className="searchitem">
										<a href="#!" className="Searchitemsview">Riggers</a>
									</div>
									<div className="searchitem">
										<a href="#!" className="Searchitemsview">Engineres</a>
									</div>
									<div className="searchitem">
										<a href="#!" className="Searchitemsview">Welders</a>
									</div>
									<div className="searchitem">
										<a href="#!" className="Searchitemsview">Fiters</a>
									</div>
									<div className="searchitem">
										<a href="#!" className="Searchitemsview">Designers</a>
									</div>
									<div className="searchitem">
										<a href="#!" className="Searchitemsview">Accounts</a>
									</div>
									<div className="searchitem">
										<a href="#!" className="Searchitemsview">Project Managers</a>
									</div>
									<div className="searchitem">
										<a href="#!" className="Searchitemsview">Welders</a>
									</div>
									<div className="searchitem">
										<a href="#!" className="Searchitemsview">Fiters</a>
									</div>
									<div className="searchitem">
										<a href="#!" className="Searchitemsview">Accounts</a>
									</div>
									<div className="searchitem">
										<a href="#!" className="Searchitemsview">Project Managers</a>
									</div>
									<div className="searchitem">
										<a href="#!" className="Searchitemsview">Riggers</a>
									</div>
									<div className="searchitem">
										<a href="#!" className="Searchitemsview">Designers</a>
									</div>
							    </div> */}
							</div>
						</div>
					</div>
					
					<div className='row'>
					{/* <div className='col-lg-6'>
					<div className="card mb-3">
						<div className="card-body">
							<div className="mb-3">
								<h4>About Company</h4>
								<p>{jobDetails.companyname} </p>
								<p>{jobDetails.companyaddress}</p>
								<p>{jobDetails.location} </p>
							</div>
							<div className="mb-3">
								<h4>Company Info</h4>
								<p><b>Website.{jobDetails.companywebsite}</b></p>
								<p>Call No.{jobDetails.contactnumber}<br />
								Email.{jobDetails.contactemail}</p>
							</div>
						</div>
					</div>
					</div> */}
					<div className='col-lg-12'>
					<div className="card mb-3">
						<div className="card-body">
							<h4>Benefits & Perks</h4>
							<div className="row ">
								<div className="col-lg-3">
									<div className="benfitssec text-center">
										<img src="assets/images/about/food1.png" alt="" />

										<h6>Free <br /> Food</h6>
									
<span
  style={{
    color: jobDetails.foodallo === "Yes" ? "green" : "red",
    fontFamily: 'Arial, sans-serif'
  }}
>
  {jobDetails.foodallo === "Yes" ? "✅" : "❌"}
</span>
									</div>
								</div>
								<div className="col-lg-3">
									<div className="benfitssec text-center">
										<img src="assets/images/about/acco1.png" alt="" />
										<h6>Free <br /> Accommodation</h6>
										
<span
  style={{
    color: jobDetails.accomadationallo === "Yes" ? "green" : "red",
    fontFamily: 'Arial, sans-serif'
  }}
>
  {jobDetails.accomadationallo === "Yes" ? "✅" : "❌"}
</span>
									</div>
								</div>
								<div className="col-lg-3">
									<div className="benfitssec text-center">
										<img src="assets/images/about/transport1.png" alt="" />
										<h6>Free <br /> Transportation</h6>
										
<span
  style={{
    color: jobDetails.transportationallo === "Yes" ? "green" : "red",
    fontFamily: 'Arial, sans-serif'
  }}
>
  {jobDetails.transportationallo === "Yes" ? "✅" : "❌"}
</span>
									</div>
								</div>
								<div className="col-lg-3">
									<div className="benfitssec text-center">
										<img src="assets/images/about/medical1.png" alt="" />
										<h6>Free <br />Medical allowance</h6>
									
<span
  style={{
    color: jobDetails.medicalallo === "Yes" ? "green" : "red",
    fontFamily: 'Arial, sans-serif'
  }}
>
  {jobDetails.medicalallo === "Yes" ? "✅" : "❌"}
</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					</div>
					</div>
{/* 					
					<div className="card mb-3">
						<div className="card-body">
							<div className="mb-3">
								<h4>Beware Of imposters</h4>
								<h5>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga....</h5>
							</div>
						</div>
					</div> */}
			  
			</div>
		  );
		}  else {
		  return <p>Invalid data format received</p>;
		}
	  };
  return (
    <div>
        <Header />
        <section className="practise-part section-p bg-light">
        <div className="container">
			<div className="row">
				<div className="col-lg-8">
<div>
{renderResponse()}
</div>


					
					{/* {response ? (
        <div>
          <h2>Data received:</h2>
          <pre>{JSON.stringify(response, null, 2)}</pre>
        </div>
      ) : (
        <p>No data received</p>
      )} 	
					 */}
					
				</div>
				<div className="col-lg-4">
					<div className="card mb-3">
						<div className="card-body">
						<h4 className="mb-2">Jobs that might interest you</h4>
<div className="facet-wrapper1" data-facet="district" >
{prime.length > 0 ? (
    <div className="row">
        {prime.map((job, index) => (
            <div key={index} className="col-12" style={{ paddingBottom: "20px" }}>
                <a href="javascript:void(0);" onClick={(e) => handleClick(e, job.id, job.designationid)}>
                    <div className="row">
                        <div className="col-lg-12">
                            <h5>{job.designation}</h5>
                            <div className="details-sec">
                                <span className="pe-3">
                                    <i className="fa fa-map-marker" aria-hidden="true"></i>&nbsp;
                                    {job.location_name}
                                </span>
                            </div>
                            <small>
                                Posted On {formatDate(`${job.posteddate}`)} <br /> Openings: {job.numberofposts}
                            </small>
                        </div>
                    </div>
                </a>
                <hr />
            </div>
        ))}
    </div>
) : (
    <p>No jobs available.</p>
)}

</div>
							
						</div>
					</div>
					
					
					
					
				
				
				
				</div>
			</div>
		</div>
    </section>
    <Footer />
    </div>
  )
}

export default ShareDetails